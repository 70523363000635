// https://vee-validate.logaretm.com/v3/overview.html#usage

import Vue from "vue"
import {
  // eslint-disable-next-line camelcase
  email, required, min, max, confirmed, min_value, max_value, double, mimes,
  size,
} from "vee-validate/dist/rules"
import {
  ValidationProvider, ValidationObserver, extend, localize, setInteractionMode,
} from "vee-validate"
import ru from "vee-validate/dist/locale/ru.json"
import en from "vee-validate/dist/locale/en.json"

function cleanFieldPlaceholders (locale: typeof ru) {
  const messages = Object.fromEntries(
    Object.entries(locale.messages)
      .map(m => [m[0], m[1].replace(" {_field_} ", " ")]),
  )
  return { ...locale, messages }
}

const plugin: NuxtPlugin = ({ app: { i18n } }) => {
  setInteractionMode("eager")

  localize({ ru: cleanFieldPlaceholders(ru), en: cleanFieldPlaceholders(en) })
  localize(i18n.locale)

  Vue.component("ValidationObserver", ValidationObserver)
  Vue.component("ValidationProvider", ValidationProvider)

  extend("required", required)
  extend("email", email)
  // extend("numeric", numeric)
  extend("min", min)
  extend("max", max)
  extend("mimes", mimes)
  extend("size", size)
  extend("min_value", min_value)
  extend("max_value", max_value)
  extend("double", double)
  extend("confirmed", confirmed)

  extend("min_length", {
    validate: (v, { length }) =>
      (v.length >= length) || i18n.t("error.minLength", [length]) as string,
    params: ["length"],
  })
  extend("max_length", {
    validate: (v, { length }) =>
      (v.length <= length) || i18n.t("error.maxLength", [length]) as string,
    params: ["length"],
  })

  // extend("ru", v => /^[а-яА-ЯёЁ0-9 -]+$/.test(v) || i18n.t("error.ru") as string)
  // extend("ru", v => /^[a-zA-Zа-яА-ЯёЁ -]+$/.test(v) || i18n.t("error.ru") as string)
  extend("ru", v => /^[а-яА-ЯёЁ -]+$/.test(v) || i18n.t("error.ru") as string)
  extend("en", v => /^[a-zA-Z0-9 -]+$/.test(v) || i18n.t("error.en") as string)
  extend("notLatin", v => !/[a-zA-Z]/.test(v) || "Только кириллица")

  extend("url", v =>
    /^https?:\/\/.+$/.test(v) || i18n.t("error.url") as string)

  extend("min_date", {
    // @ts-ignore
    validate: (v, { min }) => {
      const ls = new Date(min).toLocaleDateString()
      return v >= min || i18n.t("error.minDate", [ls])
    },
    params: ["min"],
  })

  extend("max_date", {
    // @ts-ignore
    validate: (v, { max }) => {
      const ls = new Date(max).toLocaleDateString()
      return v <= max || i18n.t("error.maxDate", [ls])
    },
    params: ["max"],
  })

  extend("vk-url", v =>
    /^https?:\/\/vk.com\/?.*$/.test(v) || "Укажите ссылку на ВК")
}
export default plugin
