
import { mapState } from "vuex"
import pickBy from "lodash/pickBy"

export default {
  props: {
    title: { type: String, default: "" },
    socials: { type: Object, default: null },
  },

  computed: {
    ...mapState("app", ["event"]),
    links () {
      return this.socials || pickBy(this.event?.links || {}, Boolean)
    },
  },
}
