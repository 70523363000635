var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.is,_vm._g(_vm._b({tag:"component",class:[_vm.$style.btn, {
    [_vm.$style.btnPrimary]: _vm.variant === 'primary',
    [_vm.$style.btnSecondary]: _vm.variant === 'secondary',
    [_vm.$style.btnOutline]: _vm.variant === 'outline',
    [_vm.$style.btnSmallOnMobile]: _vm.smallOnMobile,
    [_vm.$style.btnLoading]: _vm.loading,
  }],attrs:{"to":_vm.to || undefined,"href":_vm.href || undefined,"type":(_vm.to || _vm.href) ? undefined : 'button'}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.loading)?_c('app-spinner',{class:_vm.$style.spinner}):_vm._e(),_vm._v(" "),(_vm.text)?_c('span',{class:_vm.$style.text},[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._v(" "),(_vm.icon)?_c('app-icon',{class:_vm.$style.icon,attrs:{"name":_vm.icon,"paint":['fill']}}):_vm._e(),_vm._v(" "),_vm._t("default",null,{"textClass":_vm.$style.text,"iconClass":_vm.$style.icon})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }