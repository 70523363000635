
import Vue from "vue"

type Variant =
  | "primary"
  | "secondary"
  | "outline"

export default Vue.extend({
  props: {
    text: { type: String, default: "" },
    to: { type: String, default: "" },
    href: { type: String, default: "" },
    variant: { type: String as VuePropType<Variant>, default: "primary" },
    icon: { type: String, default: "" },
    smallOnMobile: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  computed: {
    is (): string {
      if (this.to) return "nuxt-link"
      if (this.href) return "a"
      return "button"
    },
  },
})
