// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pOfpdv1U{align-items:center;display:flex;flex-direction:column}.-sVDOLhX{font-size:.75rem;line-height:1.14;margin-bottom:max(.56rem,min(.83vw,1rem));opacity:.7;text-align:center}.K4KQUgWg{align-items:center;display:flex;flex-wrap:nowrap}.K4KQUgWg>*+*{margin-left:2rem}.jTWTMXa4{color:var(--color-fg);display:flex;flex-shrink:0}.rNLtBWqF{--size:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "pOfpdv1U",
	"title": "-sVDOLhX",
	"links": "K4KQUgWg",
	"link": "jTWTMXa4",
	"linkIcon": "rNLtBWqF"
};
module.exports = ___CSS_LOADER_EXPORT___;
