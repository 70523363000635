import type { NuxtI18nInstance } from "@nuxtjs/i18n"

// TODO: Оборачивать автоматически с сохранением типизации
// Адаптируем вызовы API в стиле golang.
export const wrap = (res: Promise<any>) => res
  .then(r => [r, null]).catch(e => [null, e.response.data])

export const wrapGql = (res: Promise<any>, query = "") => res
  .then((r) => {
    let data = r?.data
    if (!data) data = null
    else if (query) data = data[query]
    else data = Object.values(data)[0]
    return [data || null, null]
  })
  .catch((e) => {
    let data = e?.response?.data
    data = data?.errors?.[0] || data || null
    return [null, data]
  })

export const getGql = (a: NuxtAxiosInstance) =>
  (query: string, variables = {}) =>
    wrapGql(a.$post("/graphql", { query, variables }))

// Парсим мутацию из схемы в мутацию для формы
export const schToMut = (raw: string): string => {
  const lines = raw.trim().split("\n")

  const query = lines[0]
  const variables: string[] = []
  const args: string[] = []

  for (let i = 1; i < lines.length - 1; i++) {
    let l = lines[i]
    if (!l) continue
    l = l.trim()
    if (l.startsWith("\"")) continue

    const variable = `$${l.replace(/!/g, "")}`
    variables.push(variable)

    const name = l.split(":")[0]
    const arg = `${name}: $${name}`
    args.push(arg)
  }

  const result = [
    "mutation(",
    ...variables,
    "){",
    query,
    ...args,
    ")}",
  ].join("\n")

  // console.log(result)

  return result
}

/* Для @/plugins/axios */

export const parseRes = (res: any) => res?.data || null

export function parseErr (i18n: NuxtI18nInstance) {
  return (err: any) => {
    const data = err?.response?.data
    return data || err || i18n.t("error.unknownError")
    // return data?.context || data?.message || data?.data?.error ||
    //   data?.data ||
    //   err ||
    //   i18n.t("error.unknownError")
  }
}
