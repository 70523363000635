
import Vue from "vue"
import { mapGetters } from "vuex"

export default Vue.extend({
  layout: "blank",

  props: {
    error: { type: Object, required: true },
  },

  head () {
    return {
      title: this.getTitle(this.title),
    }
  },

  computed: {
    ...mapGetters("app", ["getTitle"]),

    title (): string { return this.getText("title") },
    description (): string {
      let message = this.error.message || ""
      if (message.startsWith("{")) message = ""
      return message || this.getText("description")
    },
  },

  methods: {
    getText (prop: string): string {
      return (this.$t(`error.${this.error
        .statusCode}`) as unknown as Record<string, string>)?.[prop] || ""
    },
  },
})
